<template>
  <div>
    <Pane />

    <a-card class="container">
      <Header title="补卡申请单" />

      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="申请人">
              <a-button block style="text-align: left">
                {{
                user.userName
                }}
              </a-button>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="申请部门">
              <a-button block style="text-align: left">
                {{
                department.deptUniqueName
                }}
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="补卡日期">
              <a-date-picker
                :disabled="true"
                v-decorator="[
                  'checkTime',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
                style="width: 100%"
                placeholder
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="补卡类型">
              <a-radio-group
                :disabled="true"
                v-decorator="[
                  'checkType',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              >
                <a-radio
                  v-for="(item, index) in [
                    {
                      name: '上班',
                      value: 'OnDuty',
                    },
                    {
                      name: '下班',
                      value: 'OffDuty',
                    },
                  ]"
                  :key="index"
                  :value="item.value"
                >
                  <span>{{ item.name }}</span>
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="补卡原因" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                  'remark',
                  { rules: [{ required: true, message: '请输入' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="备注" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <div class="tips">
                <span>补卡一个月不可超过两次</span>
              </div>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center footer">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="saveLoading">提交</a-button>
            <a-button @click="$router.go(-1)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>
  
<script>
import moment from "moment";
import Header from "./components/header";
import {
  addCheckApply,
  fetchCheckDetail,
  resubmitCheck
} from "@/api/human-resources/clock";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    Header
  },
  data() {
    return {
      form: this.$form.createForm(this),
      saveLoading: false
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"]),

    typeObject() {
      return {
        name: "补卡",
        remarks: "补卡页面",
        title: "补卡申请单"
      };
    }
  },
  mounted() {
    const { year, month, date, checkType, id } = this.$route.query;

    if (id) {
      this.id = id;
      fetchCheckDetail({
        id
      }).then(res => {
        if (!res) return;
        this.form.setFieldsValue({
          checkTime: moment(res.checkTime),
          checkType: res.checkType,
          remark: res.remark
        });
      });
    } else {
      const d = new Date(parseInt(year), parseInt(month) - 1, parseInt(date));

      this.form.setFieldsValue({
        checkTime: moment(d),
        checkType
      });
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          this.saveLoading = true;

          let checkTime;
          if (values.checkType === "OnDuty") {
            checkTime = values.checkTime.format("YYYY-MM-DD") + " 09:00:00";
          } else if (values.checkType === "OffDuty") {
            checkTime = values.checkTime.format("YYYY-MM-DD") + " 17:30:00";
          }

          console.log("checkTime", checkTime);

          if (this.id) {
            resubmitCheck({
              id: this.id,
              userId: this.user.uuid,
              checkTime,
              checkType: values.checkType,
              remark: values.remark
            })
              .then(() => {
                this.$router.go(-2);
              })
              .finally(() => {
                this.saveLoading = false;
              });
          } else {
            addCheckApply({
              userId: this.user.uuid,
              checkTime,
              checkType: values.checkType,
              remark: values.remark
            })
              .then(() => {
                this.$router.go(-1);
              })
              .finally(() => {
                this.saveLoading = false;
              });
          }
        }
      });
    }
  }
};
</script>
  
  
  
<style lang="less" scoped>
.footer {
  padding: 80px 0;
}
</style>