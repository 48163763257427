var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('Header',{attrs:{"title":"补卡申请单"}}),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"申请人"}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[_vm._v(" "+_vm._s(_vm.user.userName)+" ")])],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"申请部门"}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[_vm._v(" "+_vm._s(_vm.department.deptUniqueName)+" ")])],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"补卡日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'checkTime',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'checkTime',\n                {\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true,"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"补卡类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'checkType',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'checkType',\n                {\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}],attrs:{"disabled":true}},_vm._l(([
                  {
                    name: '上班',
                    value: 'OnDuty',
                  },
                  {
                    name: '下班',
                    value: 'OffDuty',
                  },
                ]),function(item,index){return _c('a-radio',{key:index,attrs:{"value":item.value}},[_c('span',[_vm._v(_vm._s(item.name))])])}),1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"补卡原因","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remark',
                { rules: [{ required: true, message: '请输入' }] },
              ]),expression:"[\n                'remark',\n                { rules: [{ required: true, message: '请输入' }] },\n              ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"备注","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('div',{staticClass:"tips"},[_c('span',[_vm._v("补卡一个月不可超过两次")])])])],1)],1),_c('div',{staticClass:"center footer"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saveLoading}},[_vm._v("提交")]),_c('a-button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }